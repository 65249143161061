<template>
  <!-- 选择城市组件 -->
  <div class="selCity">
    <p>城市</p>
    <van-field
      readonly
      clickable
      name="area"
      :value="value"
      label=""
      right-icon="arrow-down"
      placeholder="点击选择省市区"
      @click="showArea = true"
    />
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :area-list="areaList"
        @confirm="onConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
  data(){
    return{
       value: "",
      showArea: false,
      areaList,
    }
  },
  methods:{
    onConfirm(values) {
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
  }
};
</script>

<style lang='less' scoped>
.selCity{
  margin-bottom: 40px;
  p{
    font-size: 36px;
    color:#000;
    margin:0;
    margin-bottom: 10px;
  }
  /deep/.van-cell{
    // padding-left: 0;
    border-radius: 8px;
    height: 84px;
    border: 2px solid #CCCCCC;
    .van-cell__title{
      width:4em;
    }
  }
}
</style>
