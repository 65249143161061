<template>
  <!-- 简历筛选界面 -->
  <div class="resumeSel">
    <van-cell class="resumeHeader">
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <template #title>
        <van-icon name="arrow-left" @click="onClickLeft" />
        <span class="custom-title">所有职位</span>
      </template>
      <template #right-icon>
        <van-search
          v-model="value"
          shape="round"
          background="#2b8df0"
          placeholder="搜索职位/名字"
        />
        <span @click="showScreen">
          <svg-icon icon-class="resumeSel_sx"></svg-icon>
        </span>
      </template>
    </van-cell>
    <van-tabs v-model="active" swipeable color="#2B8DF0">
      <van-tab title="推荐">
        <ul>
          <li
            v-for="(item, index) in resumeList"
            :key="index"
            @click="toPersonInfo"
          >
            <div class="reListUp">
              <div class="zp">
                <img src="@/assets/img/interview_pic1.png" alt="" />
              </div>
              <div class="name">{{ item.name }}</div>
              <div class="age">
                <span>{{ item.jy }}</span>
                <span>{{ item.xl }}</span>
                <span>{{ item.age }}</span>
              </div>
            </div>
            <div class="reListDown">
              <van-row type="flex" justify="space-between">
                <van-col span="12">{{ item.gw }}</van-col>
                <van-col span="12"
                  ><span
                    :style="[
                      { color: item.zt == '初筛淘汰' ? '#999' : '#2B8DF0' },
                      {
                        border:
                          item.zt == '初筛淘汰'
                            ? '1px solid #999'
                            : '1px solid #2B8DF0',
                      },
                    ]"
                    >{{ item.zt }}</span
                  ></van-col
                >
              </van-row>
            </div>
          </li>
        </ul>
      </van-tab>
      <van-tab title="最新"> </van-tab>
    </van-tabs>
    <screen ref="screen"></screen>
  </div>
</template>

<script>
import screen from "./screen.vue";
export default {
  components: {
    screen,
  },
  data() {
    return {
      value: "",
      active: "first",
      resumeList: [
        {
          name: "周先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "初筛淘汰",
        },
        {
          name: "黄先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "待初筛",
        },
        {
          name: "陈先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "待初筛",
        },
        {
          name: "董先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "待初筛",
        },
        {
          name: "伍先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "待初筛",
        },
        {
          name: "汤先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "待初筛",
        },
      ],
    };
  },
  methods: {
    toPersonInfo() {
      this.$router.push({
        path: "/viewInfo",
        query: { footerType: "简历初筛" },
      });
    },
    onClickLeft() {
      this.$router.push("/home");
    },
    // 点击筛选弹出筛选框
    showScreen() {
      this.$refs.screen.screenShow = true;
    },
  },
};
</script>

<style lang="less" scoped>
.resumeSel {
  height: 100vh;
  .resumeHeader {
    padding-left: 30px;
    height: 88px;
    font-size: 36px;
    line-height: 48px;
    display: flex;
    align-items: center;
    font-family: Source Han Sans CN;
    background: #2b8df0;
    color: #fff;
    .svg-icon {
      font-size: 32px;
      margin-left: 28px;
    }
    .van-cell__title {
      display: flex;
      align-items: center;
      .van-icon {
        font: 12px;
      }
    }
    .van-search {
      width: 264px;
      height: 52px;
      overflow: hidden;
      padding: 0px;
      .van-search__content {
        height: 100%;
        background-color: #fff;
        // opacity: 0.9;

        .van-cell {
          display: flex;
          align-items: center;
          font-family: Source Han Sans CN;
          font-size: 28px;
          height: 100%;
          color: #fff;

          /deep/.van-field__left-icon {
            color: #2b8df0;
          }
          /deep/.van-cell__value {
            .van-field__body {
              color: #fff;
            }
          }
        }
      }
    }
  }
  /deep/.van-tabs {
    height: calc(100% - 88px);
    .van-tabs__wrap {
      .van-tabs__nav {
        padding-right: 60%;
        .van-tabs__line {
          width: 0.5em;
        }
      }
    }
    .van-tabs__content {
      height: calc(100% - 88px);
      overflow: auto;
      .van-tab__pane {
        ul {
          li {
            position: relative;
            height: 266px;
            padding: 0 30px;
            padding-bottom: 0;
            padding-top: 30px;
            background: #fff;
            box-sizing: border-box;
            margin-bottom: 24px;
            .reListUp {
              border-bottom: 1px solid #ccc;
              // margin-bottom: 20px;
              height: calc(100% - 100px);
              .zp {
                position: absolute;
                top: 28px;
                right: 54px;
                width: 106px;
                height: 106px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .name {
                font-size: 40px;
                line-height: 1;
                font-family: Source Han Sans CN;
                color: #000;
                margin-bottom: 20px;
              }
              .age {
                font-size: 28px;
                font-family: Source Han Sans CN;
                color: #666;
                padding-bottom: 40px;
                span:first-child {
                  border-right: 1px solid #999;
                  padding-right: 14px;
                }
                span:nth-child(2) {
                  margin: 0 14px;
                }
              }
            }
            .reListDown {
              height: 100px;
              line-height: 100px;
              .van-row {
                .van-col:first-child {
                  display: flex;
                  align-items: center;
                  font-size: 36px;
                  font-family: Source Han Sans CN;
                  color: #666;
                }
                .van-col:last-child {
                  text-align: right;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  span {
                    display: inline-block;
                    width: 156px;
                    height: 54px;
                    box-sizing: border-box;
                    font-size: 32px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    border: 1px solid #999;
                    color: #999;
                    line-height: 56px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
