<template>
  <!-- 简历初筛==筛选页面 -->
  <div class="screen">
    <van-popup
      v-model="screenShow"
      closeable
      position="right"
      :style="{ width: '87%', height: '100%' }"
    >
      <div class="header">
        <span class="tit">筛选</span>
      </div>
      <div class="content">
        <div class="item" v-for="(item, index) in screenList" :key="index">
          <div class="cTit">{{ item.tit }}</div>
          <ul>
            <li
              v-for="(it, idx) in item.sxItem"
              :key="idx"
              :class="it.active ? 'active' : ''"
              @click="selItem(item.sxItem, it)"
            >
              {{ it.name }}
            </li>
          </ul>
        </div>
        <selCity />
        <selpicker tit="用人部门" />
        <selpicker tit="职位" />
        <selpicker tit="招聘渠道" />
        <selpicker tit="招聘负责人" />
        <div class="footer">
          <van-button type="default">重置</van-button>
          <van-button type="info">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import selCity from "./selCity";
import selpicker from "./selpicker.vue";
export default {
  components: { selCity, selpicker },
  data() {
    return {
      screenShow: false,
      screenList: [
        {
          tit: "状态",
          sxItem: [
            { name: "全部", active: true },
            { name: "待初筛", active: false },
            { name: "初筛通过", active: false },
            { name: "初筛淘汰", active: false },
            { name: "已安排面试", active: false },
            { name: "已面试", active: false },
            { name: "面试通过", active: false },
            { name: "面试淘汰", active: false },
            { name: "拟录用", active: false },
            { name: "已发offer", active: false },
            { name: "待入职", active: false },
            { name: "已入职", active: false },
            { name: "放弃录用", active: false },
          ],
        },
        {
          tit: "学历要求",
          sxItem: [
            { name: "不限", active: true },
            { name: "初中以及以下", active: false },
            { name: "中专/中技", active: false },
            { name: "高中", active: false },
            { name: "大专", active: false },
            { name: "本科", active: false },
            { name: "研究生", active: false },
            { name: "博士", active: false },
          ],
        },
        {
          tit: "性别",
          sxItem: [
            { name: "不限", active: true },
            { name: "男", active: false },
            { name: "女", active: false },
          ],
        },
        {
          tit: "工作年限",
          sxItem: [
            { name: "不限", active: true },
            { name: "1-3年", active: false },
            { name: "3-5年", active: false },
            { name: "5-10年", active: false },
            { name: "10年以上", active: false },
          ],
        },
      ],
    };
  },
  methods: {
    selItem(list, item) {
      list.forEach((li) => {
        li.active = false;
      });
      item.active = true;
    },
  },
};
</script>

<style lang="less" scoped>
.screen {
  position: relative;
  .van-popup {
    padding-top: 76px;
    box-sizing: border-box;
    overflow: hidden;
    .header {
      text-align: center;
      .tit {
        border-bottom: 6px solid #2b8df0;
        font-size: 36px;
        color: #333;
        padding-bottom: 6px;
        box-sizing: border-box;
      }
    }
    .content {
      height: calc(100% - 108px);
      overflow: auto;
      padding: 0 30px;
      box-sizing: border-box;
      .item {
        margin-bottom: 54px;
        .cTit {
          font-size: 32px;
          color: #000;
          margin-bottom: 32px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          font-size: 28px;
          color: #000;
          li {
            width: 184px;
            height: 84px;
            line-height: 84px;
            text-align: center;
            border-radius: 8px;
            background: #e9e9e9;
            margin-bottom: 18px;
          }
          li:nth-child(3n-1) {
            margin: 0 20px;
          }
          .active {
            background: #ffffff;
            border: 2px solid #2b8df0;
            color: #2b8df0;
            box-sizing: border-box;
          }
        }
      }
      //       .selCity{
      //   background: green;
      //   /deep/.van-cell{
      //     padding-left: 0;
      //   }
      // }
      .footer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 38px;
        padding-top: 30px;
        .van-button:first-child {
          width: 196px;
          height: 95px;
          font-size: 36px;
          color: #333;
          background: #e9e9e9;
          opacity: 1;
          border-radius: 8px;
        }
        .van-button:last-child {
          width: 350px;
          height: 95px;
          background: #2b8df0;
          opacity: 1;
          font-size: 36px;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
