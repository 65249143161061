var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resumeSel"},[_c('van-cell',{staticClass:"resumeHeader",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":_vm.onClickLeft}}),_c('span',{staticClass:"custom-title"},[_vm._v("所有职位")])]},proxy:true},{key:"right-icon",fn:function(){return [_c('van-search',{attrs:{"shape":"round","background":"#2b8df0","placeholder":"搜索职位/名字"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('span',{on:{"click":_vm.showScreen}},[_c('svg-icon',{attrs:{"icon-class":"resumeSel_sx"}})],1)]},proxy:true}])}),_c('van-tabs',{attrs:{"swipeable":"","color":"#2B8DF0"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"推荐"}},[_c('ul',_vm._l((_vm.resumeList),function(item,index){return _c('li',{key:index,on:{"click":_vm.toPersonInfo}},[_c('div',{staticClass:"reListUp"},[_c('div',{staticClass:"zp"},[_c('img',{attrs:{"src":require("@/assets/img/interview_pic1.png"),"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"age"},[_c('span',[_vm._v(_vm._s(item.jy))]),_c('span',[_vm._v(_vm._s(item.xl))]),_c('span',[_vm._v(_vm._s(item.age))])])]),_c('div',{staticClass:"reListDown"},[_c('van-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(item.gw))]),_c('van-col',{attrs:{"span":"12"}},[_c('span',{style:([
                    { color: item.zt == '初筛淘汰' ? '#999' : '#2B8DF0' },
                    {
                      border:
                        item.zt == '初筛淘汰'
                          ? '1px solid #999'
                          : '1px solid #2B8DF0',
                    } ])},[_vm._v(_vm._s(item.zt))])])],1)],1)])}),0)]),_c('van-tab',{attrs:{"title":"最新"}})],1),_c('screen',{ref:"screen"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }