<template>
  <!-- 选择前组件 -->
  <div class="picker">
     <p>{{tit}}</p>
    <van-field
      readonly
      clickable
      name="picker"
      :value="value"
      label=""
       right-icon="arrow-down"
      placeholder="点击选择城市"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  props:{
    tit:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      value: "",
      columns: ["杭州", "宁波", "温州", "嘉兴", "湖州"],
      showPicker: false,

    };
  },
  methods: {
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
  },
};
</script>

<style lang='less' scoped>
.picker{
  margin-bottom: 40px;
  p{
    font-size: 36px;
    color:#000;
    margin:0;
  margin-bottom: 10px;
  
  }
  /deep/.van-cell{
     height: 84px;
    // padding-left: 0;
     border-radius: 8px;
    border: 2px solid #CCCCCC;
    .van-cell__title{
      width:4em;
    }
  }
}
</style>
